import React from 'react';
//import {useContext} from 'react';
import {graphql
  //, useStaticQuery 
        } from 'gatsby';
import Img from 'gatsby-image';

import { makeStyles } from '@material-ui/core/styles';
import {Typography, //Button, 
      CardHeader, Card, CardActionArea,CardActions,CardContent} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  cardActionBottom: {
    justifyContent: 'space-between',
    paddingTop: '0px'
  },
  chip: {
    margin: theme.spacing(1),
  },
  chipRow: {
    display: 'flex',
  },
  card: {
    width: 350,
    transition: 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)',
    '&:hover': {
      transform: 'scale(1.03, 1.03)'
    }
  },
  cardContent: {
    paddingTop: '0px',
    paddingBottom: '0px',
    height: '100px',
  }
}));

const PostSnippet = ({pagetitle, body, date, tags, slug, fixed}) => {

  const classes = useStyles();
  return (
    <Card className={classes.card} raised component="article" >
      <CardActionArea href={"/blog/" + slug}>
        <Img fixed={fixed} draggable={false} title={pagetitle} alt={pagetitle} />
      </CardActionArea>
        <CardHeader
        title={pagetitle}
      />
        <CardContent className={classes.cardContent}>    
          <Typography variant="body2" color="textSecondary" component="p">
            {body}
          </Typography>
        </CardContent>
      
      <CardActions className={classes.cardActionBottom} disableSpacing>
      {}
      </CardActions>
    </Card>
  );
}

// For images we have 4:3 Aspect Ratio
export const postFrontMatter = graphql`
  fragment PostFrontMatter on MdxFrontmatter {
    pagetitle
    summary
    date(formatString: "MMM D, YYYY")
    tags
    slug
    image {
      childImageSharp {
        fixed(width: 350, height: 280) {
          ...GatsbyImageSharpFixed
        }
      }
    }
}
`

export default PostSnippet;